import React, {Component} from 'react';

// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';
import {PhotoSwipeGallery} from 'react-photoswipe';
import { Player } from 'video-react';

// Properties
var properties = require('../../properties.json');

class Gallery extends Component {
    state = {
        // Teacher Identifier
        branchID: properties.branch_id,

        // Get All Media
        data: [],

        // Get Current Video
        currentVideo: null
    }

    componentDidMount() {
        document.title = properties.site_name + " - المعرض";

        // Get Media From API
        fetch(properties.api_path + "/media/allMedia", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                branchId: this.state.branchID
            })
        })
        .then(res => {return res.json()})
        .then(allMedia => {
            this.setState({
                data: allMedia
            });
        });
    }
    
    render() {
        // Import CSS
        require('./Gallery.scss');

        // Images
        let items = this.state.data.map((img) => {
            if (img.mediaType === 0) {
                var dim = JSON.parse(img.mediaLink.replace("gallery/", "").split("__")[2].split(".")[0]);
                return {
                    src: `${properties.uploads_path}/${img.mediaLink}`,
                    w: dim[0],
                    h: dim[1],
                    title: img.mediaLink.replace("media/", "")
                }
            }
        }).filter(Boolean);

        // Videos
        let videos = this.state.data.map(video => {
            if (video.mediaType === 1) {
                return {
                    src: `${properties.uploads_path}/video.php?videoSRC=${video.mediaLink}`,
                    thumb: `${properties.uploads_path}/${video.thumb}`,
                }
            }
        }).filter(Boolean);
        

        let getThumbnailContent = (item) => {
            return (
                <img src={item.src} alt={item.title} />
            );
        }

        let allVideos = videos.map(video => 
            <div className="col-md-4 col-lg-3 col-12">
                <div className="video" style={{backgroundImage: `url("${video.thumb}")`}} onClick={(e) => {this.setState({currentVideo: video.src})}}>
                    <div className="layer">
                        <i className="fas fa-play"></i>
                    </div>
                </div>
            </div>
        );

        return (
            <>
                <Header />
                <PageHeader pageName="المعرض" />

                <div className="gallery">
                    <div className="container">
                        <div className="images block">
                            <h3>صور</h3>
                            <PhotoSwipeGallery items={items} thumbnailContent={getThumbnailContent}/>
                        </div>

                        <div className="videos block">
                            <h3>فيديوهات</h3>
                            <div className="row">
                                {allVideos}
                            </div>

                            {(this.state.currentVideo) ? (
                                <div className="overlay">
                                    <i className="fas fa-times" onClick={(e) => {this.setState({currentVideo: null})}}></i>

                                    <div className="container">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-8">
                                                <Player
                                                    playsInline
                                                    src={this.state.currentVideo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ): ""}
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}

export default Gallery;
import React, {Component} from 'react';

// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';
import DeviceUUID from '../../Helper/device-uuid';

// Helper
import {setCookie, checkCookie} from '../../Helper/Cookies';

// Properties
var properties = require('../../properties.json');

class SignIn extends Component {
    state = {
        // Input Values
        username: null,
        password: null,
        macID: new DeviceUUID().get(),
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Sign Up Process Indicator
        buttonText: "تسجيل الدخول",
        buttonColor: "",

        // Message
        msg: {
            msgText: null,
            msgType: null,
            msgIcon: null
        }

    }

    componentDidMount() {
        document.title = properties.site_name + " - تسجيل الدخول";

        // Redirect To Months Page If The User Is Already Logged In
        if (checkCookie('email')) {
            window.location.href = "/months";
        }
    }

    render() {

        // Import CSS
        require('./SignIn.scss');

        var login = (e) => {
            let _this = this;
            e.preventDefault();

            this.setState({
                buttonText: "جاري تسجيل الدخول ...",
                buttonColor: "loading"
            });
            
            var studentDataSignIn = {
                companyId: this.state.companyID,
                branchId: this.state.branchID,
                password: this.state.password,
                userName: this.state.username,
                macId: this.state.macID
            };

            // Send the data to the server
            fetch(properties.api_path + "/authenticationStudent/login", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(studentDataSignIn)
            })
            .then(res => {return res.json()})
            .then(done => {
               _this.setState({
                   buttonText: "تسجيل الدخول",
                   buttonColor: "",
                   msg: {
                       msgText: (done.success) ? "تم تسجيل الدخول بنجاح" : done.msg,
                       msgType: (done.success) ? "success": "warning",
                       msgIcon: (done.success) ? "fas fa-check-circle" : "fas fa-times-circle"
                   }
                });

                // Redirection to Months Page & Registering a Session
                if (done.success) {
                    setTimeout(function(){
                        window.location.href = "/Months";
                        window.localStorage.setItem('currentUser', JSON.stringify(done));
                        setCookie('email', done.studentData.email);
                    }, 1000);
                }
            })
            .catch(error => {
                console.error('Error:', error)

                _this.setState({
                   buttonText: "تسجيل الدخول",
                   buttonColor: "",
                   msg: {
                       msgText: "خطأ في الإتصال",
                       msgType: "warning",
                       msgIcon: "fas fa-times-circle"
                   }
               });
            });
        }

        return (
            <>
                <Header />
                <PageHeader pageName="تسجيل الدخول" />
                <div className="sign-up-page page">
                    <div className="container">
                        <div className="note">
                            <p>جميع الحقول مطلوبة <span>*</span></p>
                        </div>
                        <form className="login-form" onSubmit={login}>
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="col-md-12">
                                        <div className="field">
                                            <label htmlFor="user-phone-email">البريد الإلكتروني أو رقم الموبايل</label>
                                            <input type="text" name="user-phone-email" id="user-phone-email" onChange={(e) => this.setState({username: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="field">
                                            <label htmlFor="pass-code">كلمة السر</label>
                                            <input type="password" name="pass-code" id="pass-code" onChange={(e) => this.setState({password: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 align-self-end">
                                        <button className={`submitReg ${this.state.buttonColor}`} name="submitReg" id="submitReg" type="submit">{this.state.buttonText}</button>
                                    </div>
                                    {(this.state.msg.msgText !== null) ? (
                                        <div className="col-md-12">
                                            <div className={`message ${this.state.msg.msgType}`}>{this.state.msg.msgText} <i className={this.state.msg.msgIcon}></i></div>
                                        </div>
                                    ): ""}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
} 

export default SignIn;
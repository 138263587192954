import React, {Component} from 'react';

// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';

// Helper
import {checkCookie} from '../../Helper/Cookies';

// Properties
var properties = require('../../properties.json');

class SignUp extends Component {
    state = {
        // Input Values
        fullName: null,
        city: null,
        cityState: null,
        studentPhone: null,
        parentPhone: null,
        parentJob: null,
        grade: null,
        gender: null,
        email: null,
        username: null,
        password: null,
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Sign Up Process Indicator
        buttonText: "إنشاء الحساب",
        buttonColor: "",

        // Cities & States & Grades
        cities: [],
        states: [],
        grades: [],

        // Message
        msg: {
            msgText: null,
            msgType: null,
            msgIcon: null
        }

    }

    componentDidMount() {
        document.title = properties.site_name + " - تسجيل حساب جديد";

        // Redirect To Months Page If The User Is Already Logged In
        console.log(checkCookie('email'));
        if (checkCookie('email')) {
            window.location.href = "/months";
        }

        // Get Cities From API
        fetch(properties.api_path + "/lockup/governorateList", {
            method: "POST"
        })
        .then(res => {return res.json()})
        .then(cities => {
            this.setState({cities: cities});
        })
        
        // Get Grades From API
        fetch(properties.api_path + "/lockup/levelList", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({branchId: this.state.branchID})
        })
        .then(res => {return res.json()})
        .then(grades => {
            this.setState({grades: grades});
        })
    }

    render() {

        // CSS
        require('./SignUp.scss');

        let cirtiesList = this.state.cities.map(city =>
            <option key={city.id} value={city.id}>{city.name}</option>
        );

        let statesList = (Array.isArray(this.state.states)) ? this.state.states.map(cityState =>
            <option key={cityState.id} value={cityState.id}>{cityState.name}</option>
        ) : [<option key={0} value="">لا يوجد مراكز</option>];
        
        let gradesList = (Array.isArray(this.state.grades)) ? this.state.grades.map(grade =>
            <option key={grade.id} value={grade.id}>{grade.name}</option>
        ) : [<option key={0} value="">لا يوجد مراحل دراسية</option>];

        var updateAndGetState = (e) => {
            this.setState({city: parseInt(e.target.value)}, () => {
                fetch(properties.api_path + "/lockup/statesList", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({id: this.state.city})
                })
                .then(res => {return res.json()})
                .then(states => {
                    this.setState({states: states});
                })
                .catch(error => {
                    console.error('Error:', error)
                })
            });
        }

        var register = (e) => {
            let _this = this;
            e.preventDefault();

            this.setState({
                buttonText: "جاري إنشاء الحساب ...",
                buttonColor: "loading"
            });
            
            var studentDataSignUp = {
                companyId: this.state.companyID,
                branchId: this.state.branchID,
                fullName: this.state.fullName,
                password: this.state.password,
                mobile: this.state.studentPhone,
                fatherMobile: this.state.parentPhone,
                fatherJob: this.state.parentJob,
                gender: this.state.gender,
                userName: this.state.username,
                stateId: this.state.cityState,
                email: this.state.email,
                schoolStagesId: this.state.grade
            };

            // Send the data to the server
            fetch(properties.api_path + "/student/studentregister", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(studentDataSignUp)
            })
            .then(res => {return res.json()})
            .then(done => {
               _this.setState({
                   buttonText: "إنشاء الحساب",
                   buttonColor: "",
                   msg: {
                       msgText: done.msg,
                       msgType: (done.msgType === "success") ? "success": "warning",
                       msgIcon: (done.msgType === "success") ? "fas fa-check-circle" : "fas fa-times-circle"
                   }
               });
            })
            .catch(error => {
                console.error('Error:', error)

                _this.setState({
                   buttonText: "إنشاء الحساب",
                   buttonColor: "",
                   msg: {
                       msgText: "خطأ في الإتصال",
                       msgType: "warning",
                       msgIcon: "fas fa-times-circle"
                   }
               });
            });
        }

        return (
            <>
                <Header />
                <PageHeader pageName="إنشاء حساب جديد" />

                <div className="sign-up-page page">
                    <div className="container">
                        <div className="note">
                            <p>جميع الحقول مطلوبة <span>*</span></p>
                        </div>
                        <form className="registeration-form" onSubmit={register}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="full-name">الإسم</label>
                                        <input required type="text" name="full-name" id="full-name" onChange={(e) => this.setState({fullName: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="city">المحافظة</label>
                                        <select required name="city" onChange={updateAndGetState}>
                                            <option value="">إختر محافظة</option>
                                            {cirtiesList}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="e-mail">البريد الإلكتروني</label>
                                        <input required type="email" name="e-mail" id="e-mail" onChange={(e) => this.setState({email: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="state">المركز</label>
                                        <select required name="state" onChange={(e) => this.setState({cityState: parseInt(e.target.value)})}>
                                            <option value="">إختر مركز</option>
                                            {statesList}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="stud-phone">تليفون الطالب</label>
                                        <input required type="tel" name="stud-phone" id="stud-phone" onChange={(e) => this.setState({studentPhone: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="gender">النوع</label>
                                        <select required name="gender" onChange={(e) => this.setState({gender: parseInt(e.target.value)})}>
                                            <option value="">إختر النوع</option>
                                            <option value={1}>ذكر</option>
                                            <option value={2}>أنثى</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="parent-phone">تليفون ولي الأمر</label>
                                        <input required type="tel" name="parent-phone" id="parent-phone" onChange={(e) => this.setState({parentPhone: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="grade">الصف الدراسي</label>
                                        <select required name="grade" onChange={(e) => this.setState({grade: parseInt(e.target.value)})}>
                                            <option value="">إختر المرحلة</option>
                                            {gradesList}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="parent-job">وظيفة ولي الأمر</label>
                                        <input required type="text" name="parent-job" id="parent-job" onChange={(e) => this.setState({parentJob: e.target.value})} />
                                    </div>
                                </div>
                                 <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="username">إسم المستخدم</label>
                                        <input required type="text" name="username" id="username" onChange={(e) => this.setState({username: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="password">كلمة السر</label>
                                        <input required type="password" name="password" id="password" onChange={(e) => this.setState({password: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-end">
                                    <button className={`submitReg ${this.state.buttonColor}`} name="submitReg" id="submitReg" type="submit">{this.state.buttonText}</button>
                                </div>
                                {(this.state.msg.msgText !== null) ? (
                                    <div className="col-md-12">
                                        <div className={`message ${this.state.msg.msgType}`}>{this.state.msg.msgText} <i className={this.state.msg.msgIcon}></i></div>
                                    </div>
                                ): ""}
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
} 

export default SignUp;
/* eslint-disable no-loop-func */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';


// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';

// Helper
import {setCookie, checkCookie} from '../../Helper/Cookies';

// Properties
var properties = require('../../properties.json');

class ViewExam extends Component {
    state = {
        // Input Values
        username: null,
        password: null,
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).studentData : {},
        
        // Exam ID
        examID: (this.props.match.params.id) ? parseInt(this.props.match.params.id) : 0,

        // Get Exam
        exam: [],

        currentAnswer: 0
    }

    componentDidMount() {        
        window.scrollTo(0, 0);

        // Redirect To Sign In Page If The User Is Already Logged Out & Remove The Data If It Exists
        if (!checkCookie('email')) {
            window.localStorage.removeItem('currentUser');
            window.location.href = "/sign-in";
        }

        // Get Exam From API
        fetch(properties.api_path + "/exams/findExams", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: this.state.examID
            })
        })
        .then(res => {return res.json()})
        .then(fetchExam => {
            this.setState({
                exam: fetchExam,
                currentAnswer: fetchExam.questionsList[0].answersdtoList[0].id
            });
        })
        .catch(error => {
            console.error('Error:', error)
        });
    }

    render() {
        document.title = properties.site_name + " - " + this.state.exam.examName;

        // Import CSS
        require('./Exams.scss');

        let getQuestions = (this.state.exam.questionsList) ? this.state.exam.questionsList.map((ques, q) => {
            let answers = ques.answersdtoList.map((ans, a) => {
                if (this.state.exam.questionsList[q].questionType === 0) {
                    return (
                        <div className="answer">
                            <div className="radio">
                                <input required={true} checked={ans.selection === 1} type="radio" id={`q${q}a${a}`} name={`q${q}a${a}`} value={this.state.exam.questionsList[q].answersdtoList.answer} onChange={(e) => {
                                    this.setState({
                                        currentAnswer: ans.id
                                    })

                                    let temp = this.state.exam;
                                    
                                    for (var c = 0; c < temp.questionsList[q].answersdtoList.length; c++) {
                                        temp.questionsList[q].answersdtoList[c].selection = 0;
                                    }

                                    temp.questionsList[q].answersdtoList[a].selection = 1;

                                    this.setState({
                                        exam: temp
                                    })
                                }} />
                                <label for={`q${q}a${a}`}><span> ({a+1}) {this.state.exam.questionsList[q].answersdtoList[a].answer} {(ans.correct === 1) ? <i style={{color: '#2eaf55'}} className="fas fa-check-circle"></i> : ""}</span></label>
                            </div>
                            {(this.state.currentAnswer === ans.id) ? (
                                <div className="note">
                                    <span>ملحوظة</span>
                                    {ans.reason}
                                </div>
                            ): ""}
                        </div>
                    );
                } else {
                    return (
                        <div className="answer">
                            <input name={`q${q}a`} type="text" placeholder="الإجابة" onChange={(e) => {
                                let temp = this.state.exam;
                                temp.questionsList[q].answersdtoList[a].answer = e.target.value;

                                this.setState({
                                    exam: temp
                                })
                            }} readOnly={true} value={ans.answer} />b

                            <div className="note">
                                <span>ملحوظة</span>
                                {ans.reason}
                            </div>
                        </div>
                    );
                }
            });

            return (
                <div className="q_item">
                <span className="index">سؤال رقم {q+1}</span>
                <span className="score">({ques.questionDegree}) الدرجة</span>
                    <h2 className="question-name">{ques.questionName}</h2>
                        <div className="answers">
                            {answers}
                        </div>
                </div>  
            );
        }): "";

        return (
            <>
                <Header />
                <PageHeader pageName={this.state.exam.examName} />

                <div className="container">
                    <div className="questions">
                        {getQuestions}      
                    </div> 
                </div>              

                <Footer />
            </>
        );
    }
} 

export default ViewExam;
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Properties
var properties = require('../../properties.json');

class Footer extends Component {
    render() {
        
        // Import CSS
        require('./Footer.scss');

        let socialIcons = Object.keys(properties.teacher.social).map((platform, i) => 
            <a href={properties.teacher.social[platform]} key={i} rel="noreferrer" target="_blank">
                <i className={`fab fa-${platform}`}></i>
            </a>
        );

        let phoneNumbers = properties.teacher.phones.map((phone, i) => 
            <a key={i} href={`tel:${phone}`}>{phone}</a>
        );
        return (
            <footer>
                <a href={`https://wa.me/${properties.teacher.whatsapp}`} target="_blank" rel="noreferrer" className="whatsapp-float"><i className="fab fa-whatsapp"></i></a>
                <div className="info">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6">
                                <div className="contact">
                                   <a href={`mailto:${properties.teacher.email}`}>{properties.teacher.email}</a>
                                   {phoneNumbers}
                                </div>
                            </div>
                            <div className="col-md-5">
                                <Link to="/">
                                    <img src={require(`../../assets/img/${properties.teacher['logo-footer']}`).default} alt={properties.site_name} />
                                </Link>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-auto">
                                <div className="social-media">
                                    {socialIcons}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copywrights">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6">
                                <span>جميع حقوق المحتويات محفوظة | {properties.site_name} 2021 - 2022</span>
                            </div>
                            <div className="col-md-6">
                                <span>تم التصميم والبرمجة في <a href="http://igma-it.com/" target="_blank" rel="noreferrer">IGMA</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;